
import { createVNode, render } from 'vue'

import type { VNode } from 'vue'

import ModalConstructor from './Modal.vue'

const container = document.getElementById('modal')

let vm: VNode;
type modalType = "success" | "error" | "warning" | "none";

type ModalOption = {
    type?: modalType;
    optionControl?: number;//0默认，1需要取消不要确认按钮，2需要确认不要取消按钮
    title?: string;
    content?: string;
    closeText?: string;
    confirmText?: string;
    icon?: string;
    modal?: boolean;
    duration?: number;
    placeholder?: string;
    dangerouslyUseHTMLString?: boolean;
    onClose?: () => void;
    onCancel?: () => void;
    onConfirm?: (args?: any) => void;
    input?: boolean;
    inputPattern?: RegExp | '';
    inputErrorMessage?: string;
}




type Modal = (params: ModalOption) => void


const Modal: Modal = function (modalOptions: ModalOption) {
    document.body.style.overflow = 'hidden'
    const destoryElement = () => {
        if (modalOptions.onClose) {
            modalOptions.onClose()
        }
        document.body.style.overflow = 'auto'
        render(null, container as HTMLElement)
    }

    const options = {
        type: modalOptions.type || 'default',
        optionControl: modalOptions.optionControl || 0,
        title: modalOptions.title,
        content: modalOptions.content,
        closeText: modalOptions.closeText,
        confirmText: modalOptions.confirmText,
        icon: modalOptions.icon || '',
        modal: modalOptions.modal || false,
        placeholder: modalOptions.placeholder || '',
        dangerouslyUseHTMLString: modalOptions.dangerouslyUseHTMLString || false,
        onClose: destoryElement,
        onCancel:modalOptions.onCancel,
        onConfirm: modalOptions.onConfirm,
        input: modalOptions.input,
        inputPattern: modalOptions.inputPattern,
        inputErrorMessage: modalOptions.inputErrorMessage,
    }
    vm = createVNode(ModalConstructor, options);
    render(vm, container as HTMLElement)
    return {
        close: destoryElement
    }

}




export default Modal
