
type ctx2D = CanvasRenderingContext2D

export interface LoadingOption {
    text?: string;
    color?: string;
    textColor?: string;
    textFont?: string;
}

export default class DrawLoading {
    /**
    *  初始化状态 
    */
    animateFunc: number | undefined;// 动画名称 用于清除动画
    percentage: number;      // 进度
    X: number;
    Y: number;
    R: number;
    startAngle: number;
    total: number;
    context: ctx2D
    color: string;
    textColor: string;
    text: string;
    textFont: string;
    fontPos: number;
    constructor(context: ctx2D, options: LoadingOption = {}) {
        this.animateFunc = 0;
        this.percentage = 0;
        this.X = 90;
        this.Y = 25;
        this.R = 15;
        this.startAngle = 0;
        this.total = 0
        this.context = context;
        this.color = options.color || '#df0024'
        this.textColor = options.textColor || '#df0024'
        this.text = options.text || '正在加载'
        this.textFont = options.textFont || '16px Consolas'
        this.fontPos = 90
    }
    getStartAngle = () => {
        return (this.startAngle) / 360 * Math.PI
    }
    getEndAngle = () => {
        return (this.startAngle + 360) / 360 * Math.PI
    }

    drawText = (text: string, x: number, y: number) => {
       
        this.context.fillText(text, x, y)
    }

    update = () => {
        //  临界 percentage === 0
        this.context.clearRect(0, 0, 600, 100);
       
        // this.drawText(this.text, this.fontPos, 70)
        this.draw()
        this.startAngle += 20
        this.animateFunc = this.animate()


    }
    // 获取进度条颜色
    getColor = () => {
        return '#df0024'
    }
    // 进度条添加动画
    animate = (): number | undefined => {
        if (!window) { return }
        return window.requestAnimationFrame(this.update);
    }
    // 描绘进度条背景凹槽
    // drawBackground = () => {
    //     this.context.beginPath();
    //     this.context.strokeStyle = 'rgb(0, 0, 0)';
    //     this.context.arc(this.X, this.Y, this.R, -Math.PI, Math.PI)
    //     this.context.stroke();
    // }
    // 描绘进度条
    draw = () => {
        // this.drawBackground()
        this.context.beginPath();
        this.context.strokeStyle = this.color;
        this.context.arc(this.X, this.Y, this.R, this.getStartAngle(), this.getEndAngle())
        this.context.stroke();
    }
    initFont = ()=>{
        this.context.font = this.textFont
        this.context.lineWidth = 2
        this.context.fillStyle = this.textColor
        this.fontPos = 90 - this.context.measureText(this.text).width/2
    }
    init = () => {
      
        this.context.scale(2,2);
        this.context.clearRect(0, 0, 600, 600);
        this.initFont()
        // this.drawBackground()
        // this.drawText('loading', this.fontPos, 0)
        this.animateFunc = this.animate()
    }


}

