
import { defineComponent, ref, reactive } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import router from "@/router";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import AddressList from "@/components/address/AddressList.vue";
export default defineComponent({
  name: "SubmitOrder",
  components: {
    // AddressList,
    Loading,
  },
  setup() {
    const listAddress = ref<{} | null>(null);
    const my = ref<{} | null>(null);
    // const checked1 = ref<string[]>([]);
    const loading = ref(true);
    const remarkValue = ref()

    function getList() {
      axios
        .get("/M/ShoppingCart/SubmitOrder", {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            listAddress.value = res.data.obj.productAddress || '';
            my.value = res.data.obj.my
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
          loading.value = false;
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
          loading.value = false;
        });
    }

     getList()


    function onSubmit() {

      // if (!listAddress.value) {
      //   Toast({
      //     type: "warning",
      //     title: "请选择收货地址",
      //   });
      //   return false;
      // }

      let tipInfo = '下单成功！客服人员将联系您确认订单'
      if (!(my.value as any).realName || !(my.value as any).userUnit) {
        tipInfo = '下单成功！客服人员将联系您确认订单，完善资料联系更方便。'
      }
      axios
        .post(
          "/M/Server/DoCheckout",
          qs.stringify({
            addressid: (listAddress.value as any).id,
            remark:remarkValue.value
          })
        )
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("showRedPackage", "true");
            Modal({
              title: "System prompt",
              content: tipInfo,
              closeText: "close",
              onClose: () => {
                router.push({
                  name: "Order",
                  query: {
                    type: 0,

                  },
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }

    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024",
    };

    return {
      listAddress,
      onSubmit,
      loadOption,
      loading,
      remarkValue
    };
  },
});
