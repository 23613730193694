<template>
  <main>
<!--    <address-list :listAddress="listAddress" addType="1"></address-list>-->

    <div class="remark">
      <div>
        <div class="remark-title"><b></b>添加备注</div>
        <div class="remark-add">选填，请先和商家协商一致</div>
      </div>
      <div class="remark-content">
        <textarea class="remark-area" rows="3" name="userMore"  v-model="remarkValue"></textarea>
      </div>
    </div>
  </main>

  <footer>
    <ul class="footer-nav">
      <li class="footer-li button" style="background-color: #df0024">
        <a @click="onSubmit" href="javascript:;" id="submit"> 提交订单 </a>
      </li>
    </ul>
  </footer>
  <loading :options="loadOption" v-if="loading"></loading>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import router from "@/router";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import AddressList from "@/components/address/AddressList.vue";
export default defineComponent({
  name: "SubmitOrder",
  components: {
    // AddressList,
    Loading,
  },
  setup() {
    const listAddress = ref<{} | null>(null);
    const my = ref<{} | null>(null);
    // const checked1 = ref<string[]>([]);
    const loading = ref(true);
    const remarkValue = ref()

    function getList() {
      axios
        .get("/M/ShoppingCart/SubmitOrder", {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            listAddress.value = res.data.obj.productAddress || '';
            my.value = res.data.obj.my
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
          loading.value = false;
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
          loading.value = false;
        });
    }

     getList()


    function onSubmit() {

      // if (!listAddress.value) {
      //   Toast({
      //     type: "warning",
      //     title: "请选择收货地址",
      //   });
      //   return false;
      // }

      let tipInfo = '下单成功！客服人员将联系您确认订单'
      if (!(my.value as any).realName || !(my.value as any).userUnit) {
        tipInfo = '下单成功！客服人员将联系您确认订单，完善资料联系更方便。'
      }
      axios
        .post(
          "/M/Server/DoCheckout",
          qs.stringify({
            addressid: (listAddress.value as any).id,
            remark:remarkValue.value
          })
        )
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("showRedPackage", "true");
            Modal({
              title: "System prompt",
              content: tipInfo,
              closeText: "close",
              onClose: () => {
                router.push({
                  name: "Order",
                  query: {
                    type: 0,

                  },
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }

    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024",
    };

    return {
      listAddress,
      onSubmit,
      loadOption,
      loading,
      remarkValue
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 109.987px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 49.987px;
  /*border-top: 1.012px solid #f4f4f4;*/
  font-size: 13.988px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 13.988px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 0.48rem;
  float: right;
}
footer .button a {
  color: #fff;
}

.footer-nav {
  padding-left: 34%;
  box-sizing: border-box;
}

.remark {
  padding: 18px;
  font-size: 14px;
  padding-bottom: 10px;
}
.remark-title {
  font-size: 16px;
  color: #333;
  text-indent: 12px;
}
.remark-title b{
  content: '';
  display: block;
  width: 5px;
  height: 20px;
  background: rgb(223, 0, 36);
  position: absolute;
}
.remark-add {
  color: #999;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 8px;
}

.remark-content {
  // border: 1px solid #eee;
}
.remark-content .remark-area {
  width: 100%;
  height: 100%;
  padding-right: 10px;
  border: 1px solid #eee;
  // border: none;
  box-sizing: border-box;
  font-family: Arial;
  margin-top: 10px;
  padding: 10px;
  outline: none;
}

</style>
