
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import Toast from "../../Toast";

type toastType = "success" | "error" | "warning" | "none";

export default defineComponent({
  name: "Modal",
  props: {
    type: {
      type: String as PropType<toastType>,
      default: "none",
    },
    optionControl: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
    dangerouslyUseHTMLString: { type: Boolean, default: false },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    modal: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 1500,
    },
    onClose: Function,
    onCancel: Function,
    onConfirm: Function,

    closeText: {
      type: String,
      default: "No",
    },
    confirmText: {
      type: String,
      default: "Yes",
    },
    placeholder: {
      type: String,
      default: "",
    },
    input:{
 type: Boolean,
      default: false,
    },
    inputPattern: {
      type: RegExp,
    },
    inputErrorMessage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const toastType = {
      success: require("./imgs/success.png"),
      error: require("./imgs/error.png"),
      warning: require("./imgs/warning.png"),
      none: "",
    };
    const closed = ref(false);
    const visible = ref(false);
    const message = ref("");
    const modalIcon = props.icon ? props.icon : toastType[props.type];
    function destoryElement() {
      visible.value = false;
      document.body.style.overflow = "auto";
      if (props.onClose) {
        (props.onClose as () => void)();
      }
    }
    watch(closed, (newVal) => {
      if (newVal) {
        destoryElement();
      }
    });
    onMounted(() => {
      visible.value = true;
    });

    
    function close() {
      if(props.onCancel){
         (props.onCancel as () => void)();
      }
      closed.value = true;
    }
    function confirm() {
      if (props.onConfirm) {
        if (props.inputPattern) {
          if (props.inputPattern.test(message.value)) {
            (props.onConfirm as (args?: any) => void)(message.value);
          }
          else {
            Toast({
              title: props.inputErrorMessage,
              type: "warning",
            });
            return false;
          }
        }else{
          props.onConfirm()
        }

      }

       closed.value = true;
    }


    return {
      modalIcon,
      close,
      visible,
      confirm,
      message,
    };
  },
});
